import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import clsx from "clsx"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { SuccessStory } from "../types"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"

interface Props {
  data: any
}

const SuccessStories: FunctionComponent<Props> = ({ data }) => {
  const stories = data.allContentfulSuccessStory.edges.map(
    (elem: any) => elem.node
  ) as SuccessStory[]

  function getTestimonials() {
    const testimonials = []
    const order = [
      "Danny Lynch",
      "Mark Hansen",
      "Mitzi Perdue",
      "Nick Johnson",
      "Harrison Loew",
      "Linus Stieldorf",
      "Simon Urban",
      "Kenneth Shortrede",
      "Jaye Wahl",
      "Kendrick Osinaike",
      "Yohn Diaz",
      "Kevin Hanes",
      "Kanishka Desai",
      "Micki Meyer",
      "Liam F",
      "Brian M.",
    ]

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className={clsx(
                "p-4 rounded-md shadow-md",
                stories[j].name === "Danny Lynch"
                  ? "bg-blue text-white"
                  : "bg-gray"
              )}
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.title}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>
              <p className="pb-4">{elem.testimonial.testimonial}</p>
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }

  return (
    <Layout>
      <SEO
        title="Success Stories"
        description="Read all the success stories and learn how Will Moore can help you to develop success habits and gamify your life!"
        link="https://mooremomentum.com/success-stories/"
      />

      <div className="bg-blue mt-4">
        <div className="container mx-auto px-4">
          <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
            Success Stories
          </h1>
        </div>
      </div>

      <div className="max-w-5xl mx-auto my-8 px-4">
        <div className="space-y-8">{getTestimonials()}</div>

        <div className="mt-8 text-center">
          <Button href="/accomplishments" link="GATSBY">
            View Will&apos;s Accomplishments
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulSuccessStory {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          name
          testimonial {
            testimonial
          }
          title
        }
      }
    }
  }
`

export default SuccessStories
